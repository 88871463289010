import React, { useContext, useEffect, useState } from "react";

import { DateRangePicker, createStaticRanges } from "react-date-range"; // FOR DATEPICKER

import moment from "moment";

import { format, differenceInDays } from "date-fns";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import Wrapper from "../helpers/Wrapper";

import CalendarIcon from "../../assets/icons/CalendarIcon";
import Button from "./Button";

import {
  setStartDate,
  setEndDate,
  setPrevStartDate,
  setPrevEndDate,
} from "../../store/dateSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { HALEON_COMPANY_ID, IDFC_COMPANY_ID } from "../../constants/constants";
import { extractData } from "../../utils/Utils";
import { IAEvent_Datepicker_Visit } from "../../utils/IAEvents";
import DatepickerAlertContext from "../../store/datepickerAlertContext";
import DataUnavailableModal from "./DataUnavailableModal";
import DataUnavailableHeader from "./DataUnavailableHeader";
import ErrorBoundary from "../../utils/ErrorBoundary";

export function formatDateDisplay(date, defaultText) {
  if (!date) return defaultText;
  return format(date, "yyyy-MM-dd");
}

function DatePicker(props) {
  const dispatch = useDispatch();


  const { showDataUnavailableModal, setShowDataUnavailableModal, showDataUnavailableHeader, setShowDataUnavailableHeader, selectedDatesObj, setSelectedDatesObj, showDataUnavailableBrands, setShowDataUnavailableBrands } = useContext(DatepickerAlertContext)



  // BRANDS PLATFORM WISE DATE
  const platformBrandsDates = useSelector((state) => state.moduleData.module);

  // USER DATA
  const userData = useSelector((state) => state.userData.user[0]);
  let companyID = userData?.company?.id ?? "";

  // TO GET THE MODULE AND SUBMODULE FORM THE ACTIVE URL
  const location = useLocation();
  let splitURL = location.pathname.split("/");
  let activeModule = splitURL[1];
  let activeSubmodule = splitURL[2] === 'social-listening' ? 'social_listening' : splitURL[2];


  // TO GET PLATFORM AND BRANDS FROM ACTIVE URL
  let [searchParams, setSearchParams] = useSearchParams();
  let activePlatform = searchParams.get("platform");
  let activeBrands = searchParams.get("brands");

  const extractedData = extractData(platformBrandsDates);


  var brandArrWDate = [];

  extractedData.map((x) => {
    if (brandArrWDate.some((y) => y.brand_id === x.brand_id)) {
      var elementPos = brandArrWDate.map(function (z) { return z.brand_id; }).indexOf(x.brand_id);

      var getObj = brandArrWDate[elementPos];

      if (new Date(x.min_date).getTime() < new Date(getObj.min_date).getTime()) {
        brandArrWDate[elementPos].min_date = x.min_date
      }

      if (new Date(x.max_date).getTime() > new Date(getObj.max_date).getTime()) {
        brandArrWDate[elementPos].max_date = x.max_date
      }
    } else {
      brandArrWDate.push(x);
    }
  });


  if (activeModule === "" || activeModule === "login" || activeModule === undefined || activeModule === null) {
    activeModule = "overall"
  }

  if (activeSubmodule === "" || activeSubmodule === undefined || activeSubmodule === null) {
    activeSubmodule = "overall"
  }

  if (activePlatform === "" || activePlatform === undefined || activePlatform === null) {
    if (activeModule === "mi" && activeSubmodule === "overall") {
      activeSubmodule = "campaigns"
      activePlatform = "overall"
    }
    else if (activeModule === "ri" && activeSubmodule === "overall") {
      activeSubmodule = companyID === IDFC_COMPANY_ID || companyID === HALEON_COMPANY_ID ? "market" : "sales"
      activePlatform = companyID === IDFC_COMPANY_ID || companyID === HALEON_COMPANY_ID ? "market" : "sales"
    }
    else {
      activePlatform = "overall"
    }
  }


  let minDateArr = [];
  let maxDateArr = [];
  let activeBrandsArr = [];

  let tempArr = [];

  if (activeModule === "overall" || /* activeSubmodule === "website" || */ activeSubmodule === "influencers" || activeSubmodule === "admin") {

    var getBrands = activeBrands?.split(',') ?? [];
    getBrands.map((x) => {
      brandArrWDate.filter((y) => {
        if (y.brand_id === x) {
          minDateArr.push(new Date(y.min_date));
          maxDateArr.push(new Date(y.max_date))
        }
      })

    })

    // minDateArr.push(new Date("2000-01-01"));
    // maxDateArr.push(new Date())
  } else {


    if (Object.keys(platformBrandsDates).length !== 0 && activeModule !== "" && activeSubmodule !== "" && activePlatform !== "" && activeBrands !== "overall") {
      if ((activeModule === "ri" && activeSubmodule === "sales") || (activeModule === "ri" && activeSubmodule === "market") || (activeModule === "mi" && activeSubmodule === "tv") || (activeModule === "ri" && activeSubmodule === "tv") || (activeModule === "mi" && activeSubmodule === "website") || (activeModule === "mi" && activeSubmodule === "social-listening")) {
        var dateObj = platformBrandsDates[activeModule][activeSubmodule][activeSubmodule][activeBrands];
        var minDateVal = Date.parse(new Date(dateObj?.min_date))
        var maxDateVal = Date.parse(new Date(dateObj?.max_date))


        minDateArr.push(isNaN(minDateVal) ? new Date("2000-01-01") : new Date(dateObj?.min_date));
        maxDateArr.push(isNaN(maxDateVal) ? new Date() : new Date(dateObj?.max_date));

      }
      else {
        if (activeModule === "admin") {
          minDateArr.push(new Date("2000-01-01"));
          maxDateArr.push(new Date())
        }
        else if (platformBrandsDates[activeModule][activeSubmodule] !== undefined) {
          // TO CHECK IF THE SELECTED PLATFORM IS AVAILABLE IN THE DATA OR NOT
          if (Object.keys(platformBrandsDates[activeModule][activeSubmodule]).includes(activePlatform)) {

            // SEPRATE BRANDS BY COMMA TO ADD IT IN THE ARRAY
            activeBrandsArr = activeBrands === undefined || activeBrands === null || activeBrands.length === 0 ? [] : activeBrands.split(",")

            // FOR GETTING THE MIN AND MAX DATE OF EACH BRAND
            activeBrandsArr.map((brand) => (
              minDateArr.push(new Date(platformBrandsDates[activeModule][activeSubmodule][activePlatform][brand]?.min_date ?? "2000-01-01")),
              maxDateArr.push(new Date(platformBrandsDates[activeModule][activeSubmodule][activePlatform][brand]?.max_date ?? new Date()))
            ))
          } else {
            // IF THE SELECTED PLATFORM IS NOT IN AVAILABLE IN DATA IT WILL SET THE DEFAULT MIN MAX DATE RANGE
            minDateArr.push(new Date("2000-01-01"));
            maxDateArr.push(new Date())
          }
        } else {
          // IF THE SELECTED PLATFORM IS NOT IN AVAILABLE IN DATA IT WILL SET THE DEFAULT MIN MAX DATE RANGE
          minDateArr.push(new Date("2000-01-01"));
          maxDateArr.push(new Date())
        }
      }


    } else if (Object.keys(platformBrandsDates).length !== 0 && activeModule !== "" && activeSubmodule !== "" && activePlatform !== "" && activeBrands === "overall") {


      if (activeModule === "admin") {
        minDateArr.push(new Date("2000-01-01"));
        maxDateArr.push(new Date())
      } else if (activeModule === "mi" && activeSubmodule === "tv") {

        activeBrandsArr = Object.keys(platformBrandsDates["mi"]["tv"]["tv"])

        // FOR GETTING THE MIN AND MAX DATE OF EACH BRAND
        activeBrandsArr.map((brand) => (
          minDateArr.push(new Date(platformBrandsDates["mi"]["tv"]["tv"][brand].min_date)),
          maxDateArr.push(new Date(platformBrandsDates["mi"]["tv"]["tv"][brand].max_date))
        ))

      } else if (activeModule === "mi" && activeSubmodule === "website") {

        activeBrandsArr = Object.keys(platformBrandsDates["mi"]["website"]["website"])

        // FOR GETTING THE MIN AND MAX DATE OF EACH BRAND
        activeBrandsArr.map((brand) => (
          minDateArr.push(new Date(platformBrandsDates["mi"]["website"]["website"][brand].min_date)),
          maxDateArr.push(new Date(platformBrandsDates["mi"]["website"]["website"][brand].max_date))
        ))

      } else if (activeModule === "ri" && activeSubmodule === "sales") {
        activeBrandsArr = Object.keys(platformBrandsDates["ri"]["sales"]["sales"])

        // FOR GETTING THE MIN AND MAX DATE OF EACH BRAND
        activeBrandsArr.map((brand) => (
          minDateArr.push(new Date(platformBrandsDates["ri"]["sales"]["sales"][brand].min_date)),
          maxDateArr.push(new Date(platformBrandsDates["ri"]["sales"]["sales"][brand].max_date))
        ))
      }
      else if (activeModule === "ri" && activeSubmodule === "market") {
        activeBrandsArr = Object.keys(platformBrandsDates["ri"]["market"]["market"])

        // FOR GETTING THE MIN AND MAX DATE OF EACH BRAND
        activeBrandsArr.map((brand) => (
          minDateArr.push(new Date(platformBrandsDates["ri"]["market"]["market"][brand].min_date)),
          maxDateArr.push(new Date(platformBrandsDates["ri"]["market"]["market"][brand].max_date))
        ))
      }

      else {
        if (platformBrandsDates[activeModule][activeSubmodule] !== undefined) {
          // TO CHECK IF THE SELECTED PLATFORM IS AVAILABLE IN THE DATA OR NOT
          if (Object.keys(platformBrandsDates[activeModule][activeSubmodule]).includes(activePlatform)) {
            activeBrandsArr = Object.keys(platformBrandsDates[activeModule][activeSubmodule][activePlatform])

            // FOR GETTING THE MIN AND MAX DATE OF EACH BRAND
            activeBrandsArr.map((brand) => (
              minDateArr.push(new Date(platformBrandsDates[activeModule][activeSubmodule][activePlatform][brand].min_date)),
              maxDateArr.push(new Date(platformBrandsDates[activeModule][activeSubmodule][activePlatform][brand].max_date))
            ))
          } else {
            // IF THE SELECTED PLATFORM IS NOT IN AVAILABLE IN DATA IT WILL SET THE DEFAULT MIN MAX DATE RANGE
            minDateArr.push(new Date("2000-01-01"));
            maxDateArr.push(new Date())
          }
        } else {
          // IF THE SELECTED PLATFORM IS NOT IN AVAILABLE IN DATA IT WILL SET THE DEFAULT MIN MAX DATE RANGE
          minDateArr.push(new Date("2000-01-01"));
          maxDateArr.push(new Date())
        }
      }

    }

  }

  // SELECTED BRANDS FROM DROPDOWN
  const selectedDates = useSelector((state) => state.date);

  // FOR SETTING THE END DATE OF CURRENT
  let current_end_date = new Date();
  current_end_date.setDate(current_end_date.getDate() - 6);

  // FOR SETTING THE START DATE OF PREVIOUS
  let previous_start_date = new Date();
  previous_start_date.setDate(previous_start_date.getDate() - 13);

  // FOR SETTING THE END DATE OF PREVIOUS
  let previous_end_date = new Date();
  previous_end_date.setDate(previous_end_date.getDate() - 7);

  const [state, setState] = useState({
    selection: {
      startDate: selectedDates.startDate === "" ? current_end_date : new Date(selectedDates.startDate),
      endDate: selectedDates.endDate === "" ? new Date() : new Date(selectedDates.endDate),
      key: "selection",
    },
    compare: {
      startDate: selectedDates.prevStartDate === "" ? previous_start_date : new Date(selectedDates.prevStartDate),
      endDate: selectedDates.prevEndDate === "" ? previous_end_date : new Date(selectedDates.prevEndDate),
      key: "compare",
    },
  });


  const applyClickHandler = (state) => {
    props.setOpen(!props.open);

    dispatch(setStartDate(formatDateDisplay(state.selection.startDate)));
    dispatch(setEndDate(formatDateDisplay(state.selection.endDate)));
    dispatch(setPrevStartDate(formatDateDisplay(state.compare.startDate)));
    dispatch(setPrevEndDate(formatDateDisplay(state.compare.endDate)));

    IAEvent_Datepicker_Visit(
      activeModule,
      activeSubmodule,
      activePlatform,
      activeBrands,
      formatDateDisplay(state.selection.startDate),
      formatDateDisplay(state.selection.endDate),
      formatDateDisplay(state.compare.startDate),
      formatDateDisplay(state.compare.endDate),
    )



  };

  const staticRanges = createStaticRanges([
    {
      label: "Today",
      range: () => ({
        startDate: moment().startOf("day").toDate(),
        endDate: moment().endOf("day").toDate(),
        fromStaticRange: true,
        identifier: "Today"
      }),
    },
    {
      label: "Yesterday",
      range: () => ({
        startDate: moment().subtract(1, "days").startOf("day").toDate(),
        endDate: moment().subtract(1, "days").endOf("day").toDate(),
        fromStaticRange: true,
        identifier: "Yesterday"
      }),
    },
    {
      label: "This Week",
      range: () => ({
        startDate: moment().startOf("week").toDate(),
        endDate: moment().endOf("week").toDate(),
        fromStaticRange: true,
        identifier: "thisWeek"
      }),
    },
    {
      label: "This Month",
      range: () => ({
        startDate: moment().startOf("month").toDate(),
        endDate: moment().endOf("day").toDate(),
        fromStaticRange: true,
        identifier: "thisMonth"
      }),
    },
    {
      label: "Last Week",
      range: () => ({
        startDate: moment().subtract(1, "weeks").startOf("week").toDate(),
        endDate: moment().subtract(1, "weeks").endOf("week").toDate(),
        fromStaticRange: true,
        identifier: "lastWeek"
      }),
    },
    {
      label: "Last 7 Days",
      range: () => ({
        startDate: moment().subtract(7, "days").startOf("day").toDate(),
        endDate: moment().subtract(1, "days").endOf("day").toDate(),
        fromStaticRange: true,
        identifier: "last7Days"
      }),
    },
    {
      label: "Last 28 Days",
      range: () => ({
        startDate: moment().subtract(28, "days").startOf("day").toDate(),
        endDate: moment().subtract(1, "days").endOf("day").toDate(),
      }),
    },
    {
      label: "Last 30 Days",
      range: () => ({
        startDate: moment().subtract(30, "days").startOf("day").toDate(),
        endDate: moment().subtract(1, "days").endOf("day").toDate(),
      }),
    },
    {
      label: "Last 90 Days",
      range: () => ({
        startDate: moment().subtract(90, "days").startOf("day").toDate(),
        endDate: moment().subtract(1, "days").endOf("day").toDate(),
      }),
    },
    {
      label: "Last 12 Months",
      range: () => ({
        startDate: moment().subtract(12, "months").startOf("month").toDate(),
        endDate: moment().subtract(1, "months").endOf("month").toDate(),
        fromStaticRange: true,
        identifier: "last12Months"
      }),
    },
    {
      label: "Last Calendar Year",
      range: () => ({
        startDate: moment().subtract(1, "years").startOf("year").toDate(),
        endDate: moment().subtract(1, "years").endOf("year").toDate(),
        fromStaticRange: true,
        identifier: "lastCalenderYear"
      }),
    },
    {
      label: "This Year (Jan - Today)",
      range: () => ({
        startDate: moment().startOf("year").toDate(),
        endDate: moment().endOf("day").toDate(),
        fromStaticRange: true,
        identifier: "thisYear"
      }),
    },
  ]);

  const HandleChangeDateRange = (item) => {

    var itemChild = item.selection !== undefined ? item.selection : item.compare !== undefined ? item.compare : item.range1

    if (itemChild.fromStaticRange !== undefined && itemChild.fromStaticRange === true) {
      if (itemChild.identifier === "Today") {
        var obj = {
          "selection": {
            startDate: moment().startOf("day").toDate(),
            endDate: moment().endOf("day").toDate(),
            key: "selection",

          },
          "compare": {
            startDate: moment().subtract(1, "days").startOf("day").toDate(),
            endDate: moment().subtract(1, "days").endOf("day").toDate(),
            key: "compare",

          }
        }

        setState({ ...state, ...obj })
      } else if (itemChild.identifier === "Yesterday") {
        var obj = {
          "selection": {
            startDate: moment().subtract(1, "days").startOf("day").toDate(),
            endDate: moment().subtract(1, "days").endOf("day").toDate(),
            key: "selection",

          },
          "compare": {
            startDate: moment().subtract(2, "days").startOf("day").toDate(),
            endDate: moment().subtract(2, "days").endOf("day").toDate(),
            key: "compare",

          }
        }

        setState({ ...state, ...obj })
      } else if (itemChild.identifier === "last7Days") {
        var obj = {
          "selection": {
            startDate: moment().subtract(7, "days").startOf("day").toDate(),
            endDate: moment().subtract(1, "days").endOf("day").toDate(),
            key: "selection",

          },
          "compare": {
            startDate: moment().subtract(14, "days").startOf("day").toDate(),
            endDate: moment().subtract(8, "days").endOf("day").toDate(),
            key: "compare",

          }
        }

        setState({ ...state, ...obj })
      } else if (itemChild.identifier === "lastWeek") {
        var obj = {
          "selection": {
            startDate: moment().subtract(1, "weeks").startOf("week").toDate(),
            endDate: moment().subtract(1, "weeks").endOf("week").toDate(),
            key: "selection",

          },
          "compare": {
            startDate: moment().subtract(2, "weeks").startOf("week").toDate(),
            endDate: moment().subtract(2, "weeks").endOf("week").toDate(),
            key: "compare",

          }
        }

        setState({ ...state, ...obj })
      } else if (itemChild.identifier === "thisWeek") {
        var obj = {
          "selection": {
            startDate: moment().startOf("week").toDate(),
            endDate: moment().endOf("day").toDate(),
            key: "selection",

          },
          "compare": {
            startDate: moment().subtract(1, "weeks").startOf("week").toDate(),
            endDate: moment().subtract(1, "weeks").endOf("week").toDate(),
            key: "compare",

          }
        }

        setState({ ...state, ...obj })
      } else if (itemChild.identifier === "thisMonth") {
        var obj = {
          "selection": {
            startDate: moment().startOf("month").toDate(),
            endDate: moment().endOf("day").toDate(),
            key: "selection",

          },
          "compare": {
            startDate: moment().subtract(1, "months").startOf("month").toDate(),
            endDate: moment().subtract(1, "months").endOf("month").toDate(),
            key: "compare",

          }
        }

        setState({ ...state, ...obj })
      } else if (itemChild.identifier === "last12Months") {
        var obj = {
          "selection": {
            startDate: moment().subtract(12, "months").startOf("month").toDate(),
            endDate: moment().subtract(1, "months").endOf("month").toDate(),
            key: "selection",

          },
          "compare": {
            startDate: moment().subtract(24, "months").startOf("month").toDate(),
            endDate: moment().subtract(13, "months").endOf("month").toDate(),
            key: "compare",

          }
        }

        setState({ ...state, ...obj })
      } else if (itemChild.identifier === "lastCalenderYear") {
        var obj = {
          selection: {
            startDate: moment().subtract(1, "years").startOf("year").toDate(),
            endDate: moment().subtract(1, "years").endOf("year").toDate(),
            key: "selection",
          },
          compare: {
            startDate: moment().subtract(2, "years").startOf("year").toDate(),
            endDate: moment().subtract(2, "years").endOf("year").toDate(),
            key: "compare",
          }
        }

        setState({ ...state, ...obj })
      } else if (itemChild.identifier === "thisYear") {
        var obj = {
          selection: {
            startDate: moment().startOf("year").toDate(),
            endDate: moment().endOf("day").toDate(),
            key: "selection",
          },
          compare: {
            startDate: moment().subtract(1, "years").startOf("year").toDate(),
            endDate: moment().subtract(1, "year").toDate(),
            key: "compare",
          }
        }
        setState({ ...state, ...obj })
      }


      delete itemChild.fromStaticRange;
      delete itemChild.identifier;
    } else if (item.selection !== undefined) {
      const date1 = new Date(item.selection.startDate);
      const date2 = new Date(item.selection.endDate);
      const diffTime = Math.abs(date2 - date1);
      const totalDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;

      var previousEndDate = new Date(date1.setDate(date1.getDate() - 1))
      var previousStartDate = new Date(date1.setDate(date1.getDate() - (totalDays - 1)))
      var dateObj = {
        compare: {
          startDate: previousStartDate,
          endDate: previousEndDate,
          key: "compare",
        },
      }
      setState({ ...state, ...item, ...dateObj })
    } else {
      setState({ ...state, ...item })
    }
  }


  useEffect(() => {

    try {
      let getMinDateTime = new Date(Math.min(...minDateArr)).getTime();
      let getMaxDateTime = new Date(Math.max(...maxDateArr)).getTime();
      let getStartDateTime = new Date(selectedDates.startDate).getTime();
      let getPrevStartDate = new Date(selectedDates.prevStartDate).getTime()
      let getPrevEndDate = new Date(selectedDates.prevEndDate).getTime()


      setSelectedDatesObj({
        minDate: getMinDateTime,
        maxDate: getMaxDateTime
      })

      if (
        (getMinDateTime > getStartDateTime) || (getMaxDateTime < getStartDateTime) ||
        ((getMinDateTime > getPrevStartDate) && (getMinDateTime > getPrevEndDate))
      ) {
        setShowDataUnavailableModal(true)
      } else {
        setShowDataUnavailableModal(false)
        setShowDataUnavailableHeader(false)
      }

    } catch (error) {
      console.error('Datepicker Error', error)
      setShowDataUnavailableModal(false)
      setShowDataUnavailableHeader(false)
      setSelectedDatesObj({
        minDate: new Date(),
        maxDate: new Date('2000-01-01'),
      })
    }

  }, [selectedDates, location.key])





  return (
    <Wrapper>
      <ErrorBoundary chartType={'datepickerChartType'}>
        <DataUnavailableModal
          open={showDataUnavailableModal}
          setOpen={setShowDataUnavailableModal}
          setHeader={setShowDataUnavailableHeader}
          handleDatepicker={props.setOpen}
          minDate={new Date(Math.min(...minDateArr))}
          maxDate={new Date(Math.max(...maxDateArr))}
          brandList={showDataUnavailableBrands}
        />
        <DataUnavailableHeader show={showDataUnavailableHeader} dateValues={selectedDatesObj} setShowDataUnavailableHeader={setShowDataUnavailableHeader} brandList={showDataUnavailableBrands} />
      </ErrorBoundary>

      <div
        onClick={() => props.setOpen(!props.open)}
        className="datepicker_wrapper"
      >
        <div className="datepicker_section">
          <div className="datepicker">
            <div className="date_title">
              {formatDateDisplay(state.selection.startDate)}
            </div>
            <div className="date_subtitle">to</div>
            <div className="date_title">
              {formatDateDisplay(state.selection.endDate)}
            </div>
          </div>
          <CalendarIcon className="icon" />
        </div>
      </div>
      {props.open && (
        <div className="datepicker_dropdown_wrapper">
          <DateRangePicker
            className="datepicker_calender"
            editableDateInputs={true}
            onChange={(item) => HandleChangeDateRange(item)}
            direction={"vertical"}
            scroll={{ enabled: true }}
            ranges={[state.selection, state.compare]}
            color="#11a1fd"
            rangeColors={["#11a1fd", "#FF9931"]}
            staticRanges={staticRanges}
            minDate={minDateArr.length === 0 ? new Date('2000-01-01') : new Date(Math.min(...minDateArr))}
            maxDate={maxDateArr.length === 0 ? new Date() : new Date(Math.max(...maxDateArr))}
          />
          <div className="datepicker_footer">
            <div className="btn_wrapper">
              <Button handleClick={() => props.setOpen(!props.open)} className="secondary_danger_btn">
                Cancel
              </Button>
            </div>
            <div className="btn_wrapper">
              <Button handleClick={() => applyClickHandler(state)} className="primary_btn">
                Apply
              </Button>
            </div>
          </div>
        </div>
      )}
    </Wrapper>
  );
}
export default DatePicker;